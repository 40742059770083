import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import { useBannerByIdQuery, useSalesByIdQuery } from "../../service";
import { setBannerView } from "../../redux/bannerSlice";
import { setSalesView } from "../../redux/salesSlice";

function SPView() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewData = useSelector((state) => state.salesState.salesView);
  const resSalesById = useSalesByIdQuery(params.id);

  useEffect(() => {
    if (resSalesById?.isSuccess) {
      dispatch(setSalesView(resSalesById?.data?.data));
    }
  }, [resSalesById]);

  return (
    <Card>
      <CardHeader className="align-items-end">
        <h5 className="text-center">Sales Details: {viewData?.name}</h5>
        <Button color="primary" onClick={() => navigate("/sales-list")}>
          Back
        </Button>
      </CardHeader>
      <CardBody>
        <Table bordered>
          <tbody>
            <tr>
              <th style={{ width: "20%" }}>Title</th>
              <td style={{ width: "80%" }}>{viewData?.name}</td>
            </tr>
            <tr>
              <th style={{ width: "20%" }}>Image</th>
              <td style={{ width: "80%" }}>
                {viewData?.image?.filepath ? (
                  <img src={viewData?.image?.filepath} height={50} width={50} />
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <th style={{ width: "20%" }}>Content</th>
              <td style={{ width: "80%" }}>{viewData?.content}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

export default SPView;
