import { createSlice } from "@reduxjs/toolkit";

const initState = {
  tutorialList: [],
  tutorialView: null,
  tutorialEdit: null,
  tutorialCategoryList: [],
  tutorialCategoryView: null,
  tutorialCategoryEdit: null,
  tutorialCategoryDropdown:[]
};

export const tutorialSlice = createSlice({
  name: "tutorial",
  initialState: initState,
  reducers: {
    getTutorial: (state, { payload }) => {
      state.tutorialList = payload;
    },
    setTutorialView: (state, { payload }) => {
      state.tutorialView = payload;
    },
    setTutorialEdit: (state, { payload }) => {
      state.tutorialEdit = payload;
    },
    getTutorialCategory: (state, { payload }) => {
        state.tutorialCategoryList = payload;
      },
      setTutorialCategoryView: (state, { payload }) => {
        state.tutorialCategoryView = payload;
      },
      setTutorialCategoryEdit: (state, { payload }) => {
        state.tutorialCategoryEdit = payload;
      },
      setTutorialCategoryDropDown: (state, { payload }) => {
        state.tutorialCategoryDropdown = payload;
      },
  },
});

export const { getTutorial, setTutorialView, setTutorialEdit,
getTutorialCategory,setTutorialCategoryView,setTutorialCategoryEdit,setTutorialCategoryDropDown
} =
  tutorialSlice.actions;
export default tutorialSlice.reducer;
