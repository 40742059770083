import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  authApi,
  createUserApi,
  createAdminApi,
  categoryApi,
  courseApi,
  centerApi,
  batchApi,
  enquiryApi,
  fileApi,
  clientApi,
  galleryApi,
  faqApi,
  placementApi,
  blogApi,
  contactUsApi,
  aboutUsApi,
  privacyPolicyApi,
  copyRightApi,
  refundPolicyApi,
  termConditionApi,
  testimonialApi,
  certificateApi,
  bannerApi,
  linksApi,
  reqBrochureMailApi,
  tutorialApi,
  landingPageOneApi,
  landingPageTwoApi,
  salesApi,
  tutorialBannerApi,
  notificationApi
} from "../service";
import courseSlice from "./courseSlice";
import categorySlice from "./categorySlice";
import centerSlice from "./centerSlice";
import batchSlice from "./batchSlice";
import enquirySlice from "./enquirySlice";
import clientSlice from "./clientSlice";
import gallarySlice from "./gallarySlice";
import faqSlice from "./faqSlice";
import placementSlice from "./placementSlice";
import blogSlice from "./blogSlice";
import contactUsSlice from "./contactUsSlice";
import testimonialSlice from "./testimonialSlice";
import certificateSlice from "./certificateSlice";
import bannerSlice from "./bannerSlice";
import reqBrochureMailSlice from "./reqBrochureMailSlice";
import tutorialSlice from "./tutorialSlice";
import salesSlice from "./salesSlice";
import userSlice from "./userSlice";
import adminSlice from "./adminSlice";
import authSlice from "./authSlice";

const appReducer = combineReducers({
  authState:authSlice,
  courseState: courseSlice,
  categoryState: categorySlice,
  centerState: centerSlice,
  batchState: batchSlice,
  enquiryState: enquirySlice,
  clientState: clientSlice,
  gallaryState: gallarySlice,
  faqState: faqSlice,
  placementState: placementSlice,
  blogState: blogSlice,
  contactUsState: contactUsSlice,  
  testimonialState: testimonialSlice,
  certificateState: certificateSlice,
  bannerState: bannerSlice,
  reqBrochureMailState: reqBrochureMailSlice,
  tutorialState: tutorialSlice,
  salesState: salesSlice,
  userState:userSlice,
  adminState:adminSlice,
  [authApi.reducerPath]: authApi.reducer,
  [createUserApi.reducerPath]: createUserApi.reducer,
  [createAdminApi.reducerPath]: createAdminApi.reducer,
  [courseApi.reducerPath]: courseApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [centerApi.reducerPath]: centerApi.reducer,
  [enquiryApi.reducerPath]: enquiryApi.reducer,
  [batchApi.reducerPath]: batchApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [galleryApi.reducerPath]: galleryApi.reducer,
  [faqApi.reducerPath]: faqApi.reducer,
  [placementApi.reducerPath]: placementApi.reducer,
  [blogApi.reducerPath]: blogApi.reducer,
  [contactUsApi.reducerPath]: contactUsApi.reducer,
  [aboutUsApi.reducerPath]: aboutUsApi.reducer,
  [privacyPolicyApi.reducerPath]: privacyPolicyApi.reducer,
  [copyRightApi.reducerPath]: copyRightApi.reducer,
  [refundPolicyApi.reducerPath]: refundPolicyApi.reducer,
  [termConditionApi.reducerPath]: termConditionApi.reducer,
  [testimonialApi.reducerPath]: testimonialApi.reducer,
  [certificateApi.reducerPath]: certificateApi.reducer,
  [bannerApi.reducerPath]: bannerApi.reducer,
  [linksApi.reducerPath]: linksApi.reducer,
  [reqBrochureMailApi.reducerPath]: reqBrochureMailApi.reducer,
  [tutorialApi.reducerPath]: tutorialApi.reducer,
  [landingPageOneApi.reducerPath]: landingPageOneApi.reducer,
  [landingPageTwoApi.reducerPath]: landingPageTwoApi.reducer,
  [salesApi.reducerPath]: salesApi.reducer,
  [tutorialBannerApi.reducerPath]: tutorialBannerApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
});

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaltMiddleware) =>
    getDefaltMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      createUserApi.middleware,
      createAdminApi.middleware,
      courseApi.middleware,
      categoryApi.middleware,
      batchApi.middleware,
      centerApi.middleware,
      enquiryApi.middleware,
      fileApi.middleware,
      clientApi.middleware,
      galleryApi.middleware,
      faqApi.middleware,
      placementApi.middleware,
      blogApi.middleware,
      contactUsApi.middleware,
      aboutUsApi.middleware,
      privacyPolicyApi.middleware,
      copyRightApi.middleware,
      refundPolicyApi.middleware,
      termConditionApi.middleware,
      testimonialApi.middleware,
      certificateApi.middleware,
      bannerApi.middleware,
      linksApi.middleware,
      reqBrochureMailApi.middleware,
      tutorialApi.middleware,
      landingPageOneApi.middleware,
      landingPageTwoApi.middleware,
      salesApi.middleware,
      tutorialBannerApi.middleware,
      notificationApi.middleware,
    ]),
});