import { createSlice } from "@reduxjs/toolkit";

const initState = {
  brochureList: [],
  mailList: [],
};

export const reqBrochureMailSlice = createSlice({
  name: "reqBrochureMail",
  initialState: initState,
  reducers: {
    getBrochure: (state, { payload }) => {
      state.brochureList = payload;
    },
    getMail: (state, { payload }) => {
        state.mailList = payload;
      },
  },
});

export const { getBrochure,getMail } =
  reqBrochureMailSlice.actions;
export default reqBrochureMailSlice.reducer;
