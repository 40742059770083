import { createSlice } from "@reduxjs/toolkit";

const initState = {
  bannerList: [],
  bannerView: null,
  bannerEdit: null,
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState: initState,
  reducers: {
    getBanner: (state, { payload }) => {
      state.bannerList = payload;
    },
    setBannerView: (state, { payload }) => {
      state.bannerView = payload;
    },
    setBannerEdit: (state, { payload }) => {
      state.bannerEdit = payload;
    },
  },
});

export const { getBanner, setBannerView, setBannerEdit } =
  bannerSlice.actions;
export default bannerSlice.reducer;
