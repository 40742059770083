import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const auth = localStorage.getItem('auth')
const user = JSON.parse(auth)
console.log("user",user?.token)


const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    :  process.env.REACT_APP_PROD_URL;

export const authApi = createApi({
  tagTypes: ["auth"],
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`
  }),
  endpoints: (builder) => ({
    loginAuth: builder.mutation({
      query: (payload) => ({
        url: "admin/login",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["auth"],
    }),
    loginAsAdmin:builder.mutation({
      query:(payload) => ({
        url:"admin/staff/login/admin",
        method:'POST',
        body:payload,
      }),
      invalidatesTags:["auth"],
    })
  }),
});
export const {
  useLoginAuthMutation,
  useLoginAsAdminMutation
} = authApi;

export const createUserApi = createApi({
  tagTypes:["user"],
  reducerPath:"createUserApi",
  baseQuery:fetchBaseQuery({
    baseUrl:`${baseUrl}/`,
    prepareHeaders:(headers,{getState})=>{
      headers.set('Authorization', getState()?.authState?.userToken);
      return headers
    }  
  }),
  endpoints: (builder)=>({
    userList:builder.mutation({
      query:(payload)=>({
        url:"user/list",
        method:"POST",
        body:payload,
      }),
      providesTags:["user"]
    }),
    createUser:builder.mutation({
      query:(payload)=>({
        url: "user/create",
        method: "POST",
        body: payload,
      }),
      providesTags:["user"]
    }),    
  })
})
export const {
  useUserListMutation,useCreateUserMutation,
} = createUserApi;

export const createAdminApi = createApi({
  tagTypes:["Admin"],
  reducerPath:"createAdminApi",
  baseQuery:fetchBaseQuery({
    baseUrl:`${baseUrl}/`,
    prepareHeaders:(headers,{getState})=>{
      headers.set('Authorization', getState()?.authState?.userToken);
      return headers
    }  
  }),
  endpoints: (builder)=>({
    adminList:builder.mutation({
      query:(payload)=>({
        url:"user/admin/list",
        method:"POST",
        body:payload,
      }),
      providesTags:["admin"]
    }),
    createAdmin:builder.mutation({
      query:(payload)=>({
        url: "user/admin/create",
        method: "POST",
        body: payload,
      }),
      providesTags:["admin"]
    }),    
  })
})
export const {
  useAdminListMutation,useCreateAdminMutation,
} = createAdminApi;


export const courseApi = createApi({
  tagTypes: ["course"],
  reducerPath: "courseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    courseList: builder.mutation({
      query: (payload) => ({
        url: "course/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["course"],
    }),
    getAllCourseDropdown: builder.query({
      query: () => ({
        url: "course/dropdown",
        method: "GET",
      }),
      providesTags: ["course"],
    }),
    submitCourse: builder.mutation({
      query: (payload) => ({
        url: "course",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["course"],
    }),
    courseById: builder.query({
      query: (id) => ({
        url: `course/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["course"],
    }),
    courseBySeoUrl: builder.query({
      query: (id) => ({
        url: `course/view/${id}`,
        method: "GET",
      }),
      providesTags: ["course"],
    }),
    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `course/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["course"],
    }),
    downloadCousreCsv: builder.mutation({
      query: (payload) => ({
        url: "course/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["course"],
    }),
  }),
});
export const {
  useCourseListMutation,
  useGetAllCourseDropdownQuery,
  useSubmitCourseMutation,
  useCourseByIdQuery,
  useCourseBySeoUrlQuery,
  useDeleteCourseMutation,
  useDownloadCousreCsvMutation,
} = courseApi;

export const categoryApi = createApi({
  refetchOnMountOrArgChange: true,
  tagTypes: ["category"],
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    categoryList: builder.mutation({
      query: (payload) => ({
        url: "course/category/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["category"],
    }),
    getAllCategoryDropdown: builder.query({
      query: () => ({
        url: "course/category/dropdown",
        method: "GET",
      }),
      providesTags: ["category"],
    }),
    submitCategory: builder.mutation({
      query: (payload) => ({
        url: "course/category",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["category"],
    }),
    categoryById: builder.query({
      query: (id) => ({
        url: `course/category/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["category"],
    }),
    categoryBySeoUrl: builder.query({
      query: (id) => ({
        url: `course/category/view/${id}`,
        method: "GET",
      }),
      providesTags: ["category"],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `course/category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["category"],
    }),
    downloadCategoryCsv: builder.mutation({
      query: (payload) => ({
        url: "course/category/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["category"],
    }),
  }),
});
export const {
  useCategoryListMutation,
  useGetAllCategoryDropdownQuery,
  useSubmitCategoryMutation,
  useCategoryByIdQuery,
  useDeleteCategoryMutation,
  useDownloadCategoryCsvMutation,
  useCategoryBySeoUrlQuery
} = categoryApi;

export const centerApi = createApi({
  tagTypes: ["center"],
  reducerPath: "centerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    centerList: builder.mutation({
      query: (payload) => ({
        url: "center/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["center"],
    }),
    getAllCenterDropdown: builder.query({
      query: () => ({
        url: "center/dropdown",
        method: "GET",
      }),
      providesTags: ["center"],
    }),
    submitCenter: builder.mutation({
      query: (payload) => ({
        url: "center",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["center"],
    }),
    centerById: builder.query({
      query: (id) => ({
        url: `center/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["center"],
    }),
    deleteCenter: builder.mutation({
      query: (id) => ({
        url: `center/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["center"],
    }),
    downloadCenterCsv: builder.mutation({
      query: (payload) => ({
        url: "center/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["center"],
    }),
  }),
});
export const {
  useCenterListMutation,
  useGetAllCenterDropdownQuery,
  useSubmitCenterMutation,
  useCenterByIdQuery,
  useDeleteCenterMutation,
  useDownloadCenterCsvMutation,
} = centerApi;

export const batchApi = createApi({
  tagTypes: ["batch"],
  reducerPath: "batchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    batchList: builder.mutation({
      query: (payload) => ({
        url: "batch/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["batch"],
    }),
    submitBatch: builder.mutation({
      query: (payload) => ({
        url: "batch",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["batch"],
    }),
    batchById: builder.query({
      query: (id) => ({
        url: `batch/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["batch"],
    }),
    deleteBatch: builder.mutation({
      query: (id) => ({
        url: `batch/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["batch"],
    }),
    downloadBatchCsv: builder.mutation({
      query: (payload) => ({
        url: "batch/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["batch"],
    }),
  }),
});
export const {
  useBatchListMutation,
  useSubmitBatchMutation,
  useBatchByIdQuery,
  useDeleteBatchMutation,
  useDownloadBatchCsvMutation,
} = batchApi;

export const enquiryApi = createApi({
  tagTypes: ["enquiry"],
  reducerPath: "enquiryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    enquiryList: builder.mutation({
      query: (payload) => ({
        url: "enquiry/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["enquiry"],
    }),
    submitEnquiry: builder.mutation({
      query: (payload) => ({
        url: "enquiry",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["enquiry"],
    }),
    enquiryById: builder.query({
      query: (id) => ({
        url: `enquiry/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["enquiry"],
    }),
    deleteEnquiry: builder.mutation({
      query: (id) => ({
        url: `enquiry/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["enquiry"],
    }),
    downloadEnquiryCsv: builder.mutation({
      query: (payload) => ({
        url: "enquiry/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["enquiry"],
    }),
  }),
});

export const {
  useEnquiryListMutation,
  useSubmitEnquiryMutation,
  useEnquiryByIdQuery,
  useDeleteEnquiryMutation,
  useDownloadEnquiryCsvMutation,
} = enquiryApi;

export const fileApi = createApi({
  tagTypes: ["fileUpload"],
  reducerPath: "fileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fileUpload: builder.mutation({
      query: (payload) => ({
        url: `uploads?type=${payload?.type}`,
        method: "POST",
        body: payload?.file,
      }),
      invalidatesTags: ["file"],
    }),
  }),
});

export const { useFileUploadMutation } = fileApi;

export const clientApi = createApi({
  tagTypes: ["client"],
  reducerPath: "clientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    clientList: builder.mutation({
      query: (payload) => ({
        url: "client/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["client"],
    }),
    submitClient: builder.mutation({
      query: (payload) => ({
        url: "client",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["client"],
    }),
    clientById: builder.query({
      query: (id) => ({
        url: `client/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["client"],
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `client/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["client"],
    }),
    downloadClientCsv: builder.mutation({
      query: (payload) => ({
        url: "client/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["client"],
    }),
  }),
});
export const {
  useClientListMutation,
  useSubmitClientMutation,
  useClientByIdQuery,
  useDeleteClientMutation,
  useDownloadClientCsvMutation,
} = clientApi;

export const galleryApi = createApi({
  tagTypes: ["gallery"],
  reducerPath: "galleryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    galleryList: builder.mutation({
      query: (payload) => ({
        url: "gallery/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["gallery"],
    }),
    submitGallery: builder.mutation({
      query: (payload) => ({
        url: "gallery",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["gallery"],
    }),
    galleryById: builder.query({
      query: (id) => ({
        url: `gallery/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["gallery"],
    }),
    deleteGallery: builder.mutation({
      query: (id) => ({
        url: `gallery/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["gallery"],
    }),
    downloadGalleryCsv: builder.mutation({
      query: (payload) => ({
        url: "gallery/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["gallery"],
    }),
  }),
});
export const {
  useGalleryListMutation,
  useSubmitGalleryMutation,
  useGalleryByIdQuery,
  useDeleteGalleryMutation,
  useDownloadGalleryCsvMutation,
} = galleryApi;

export const faqApi = createApi({
  tagTypes: ["faq", "faq-category"],
  reducerPath: "faqApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    faqList: builder.mutation({
      query: (payload) => ({
        url: "faq/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["faq"],
    }),
    submitFaq: builder.mutation({
      query: (payload) => ({
        url: "faq",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["faq"],
    }),
    faqById: builder.query({
      query: (id) => ({
        url: `faq/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["faq"],
    }),
    deleteFaq: builder.mutation({
      query: (id) => ({
        url: `faq/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["faq"],
    }),
    downloadFaqCsv: builder.mutation({
      query: (payload) => ({
        url: "faq/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["faq"],
    }),
    faqCategoryList: builder.mutation({
      query: (payload) => ({
        url: "faq-category/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["faq-category"],
    }),
    submitFaqCategory: builder.mutation({
      query: (payload) => ({
        url: "faq-category",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["faq-category"],
    }),
    faqCategoryById: builder.query({
      query: (id) => ({
        url: `faq-category/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["faq-category"],
    }),
    deleteFaqCategory: builder.mutation({
      query: (id) => ({
        url: `faq-category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["faq-category"],
    }),
    downloadFaqCategoryCsv: builder.mutation({
      query: (payload) => ({
        url: "faq-category/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["faq-category"],
    }),
  }),
});
export const {
  useFaqListMutation,
  useSubmitFaqMutation,
  useFaqByIdQuery,
  useDeleteFaqMutation,
  useDownloadFaqCsvMutation,
  useFaqCategoryListMutation,
  useSubmitFaqCategoryMutation,
  useFaqCategoryByIdQuery,
  useDeleteFaqCategoryMutation,
  useDownloadFaqCategoryCsvMutation,
} = faqApi;

export const placementApi = createApi({
  tagTypes: ["drive","placedStudent", 'studentReg',],
  reducerPath: "placementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    driveList: builder.mutation({
      query: (payload) => ({
        url: "drive/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["drive"],
    }),
    submitDrive: builder.mutation({
      query: (payload) => ({
        url: "drive",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["drive"],
    }),
    driveById: builder.query({
      query: (id) => ({
        url: `drive/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["drive"],
    }),
    deleteDrive: builder.mutation({
      query: (id) => ({
        url: `drive/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["drive"],
    }),
    downloadDriveCsv: builder.mutation({
      query: (payload) => ({
        url: "drive/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["drive"],
    }),
    placedStudentList: builder.mutation({
      query: (payload) => ({
        url: "placed/student/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["placedStudent"],
    }),
    submitPlacedStudent: builder.mutation({
      query: (payload) => ({
        url: "placed/student",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["placedStudent"],
    }),
    placedStudentById: builder.query({
      query: (id) => ({
        url: `placed/student/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["placedStudent"],
    }),
    deletePlacedStudent: builder.mutation({
      query: (id) => ({
        url: `placed/Student/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["placedStudent"],
    }),
    downloadPlacedStudentCsv: builder.mutation({
      query: (payload) => ({
        url: "placed/student/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["placedStudent"],
    }),
    studentRegList: builder.mutation({
      query: (payload) => ({
        url: "studentReg/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["studentReg"],
    }),
    submitStudentReg: builder.mutation({
      query: (payload) => ({
        url: "studentReg",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["studentReg"],
    }),
    studentRegById: builder.query({
      query: (id) => ({
        url: `studentReg/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["studentReg"],
    }),
    deleteStudentReg: builder.mutation({
      query: (id) => ({
        url: `studentReg/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["studentReg"],
    }),
    downloadStudentRegCsv: builder.mutation({
      query: (payload) => ({
        url: "studentReg/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["studentReg"],
    }),
  }),
});
export const {
  useDriveListMutation,
  useSubmitDriveMutation,
  useDriveByIdQuery,
  useDeleteDriveMutation,
  useDownloadDriveCsvMutation,
  usePlacedStudentListMutation,
  useSubmitPlacedStudentMutation,
  usePlacedStudentByIdQuery,
  useDeletePlacedStudentMutation,
  useDownloadPlacedStudentCsvMutation,
  useStudentRegListMutation,
  useSubmitStudentRegMutation,
  useStudentRegByIdQuery,
  useDeleteStudentRegMutation,
  useDownloadStudentRegCsvMutation,
} = placementApi;

export const testimonialApi = createApi({
  tagTypes: ["testimonial"],
  reducerPath: "testimonialApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    testimonialList: builder.mutation({
      query: (payload) => ({
        url: "testimonial/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["testimonial"],
    }),
    submitTestimonial: builder.mutation({
      query: (payload) => ({
        url: "testimonial",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["testimonial"],
    }),
    testimonialById: builder.query({
      query: (id) => ({
        url: `testimonial/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["testimonial"],
    }),
    deleteTestimonial: builder.mutation({
      query: (id) => ({
        url: `testimonial/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["testimonial"],
    }),
    downloadTestimonialCsv: builder.mutation({
      query: (payload) => ({
        url: "testimonial/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["testimonial"],
    }),
  }),
});
export const {
  useTestimonialListMutation,
  useSubmitTestimonialMutation,
  useTestimonialByIdQuery,
  useDeleteTestimonialMutation,
  useDownloadTestimonialCsvMutation,
} = testimonialApi;

export const contactUsApi = createApi({
  tagTypes: ["contactUs"],
  reducerPath: "contactUsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    contactUsList: builder.mutation({
      query: (payload) => ({
        url: "contactUs/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["contactUs"],
    }),
    submitContactUs: builder.mutation({
      query: (payload) => ({
        url: "contactUs",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["contactUs"],
    }),
    contactUsById: builder.query({
      query: (id) => ({
        url: `contactUs/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["contactUs"],
    }),
    deleteContactUs: builder.mutation({
      query: (id) => ({
        url: `contactUs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["contactUs"],
    }),
    downloadContactUsCsv: builder.mutation({
      query: (payload) => ({
        url: "contactUs/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["contactUs"],
    }),
  }),
});
export const {
  useContactUsListMutation,
  useSubmitContactUsMutation,
  useContactUsByIdQuery,
  useDeleteContactUsMutation,
  useDownloadContactUsCsvMutation,
} = contactUsApi;

export const aboutUsApi = createApi({
  tagTypes: ["aboutUs"],
  reducerPath: "aboutUsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchAboutUs: builder.query({
      query: (payload) => ({
        url: "appsetting/aboutUs",
        method: "GET",
      }),
      providesTags: ["aboutUs"],
    }),
    submitAboutUs: builder.mutation({
      query: (payload) => ({
        url: "appsetting/aboutUs",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["aboutUs"],
    }),
    fetchAboutUsNew: builder.query({
      query: (payload) => ({
        url: "appsetting/aboutus/v1",
        method: "GET",
      }),
      providesTags: ["aboutUs"],
    }),
    submitAboutUsNew: builder.mutation({
      query: (payload) => ({
        url: "appsetting/aboutus/v1",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["aboutUs"],
    }),
  }),
});
export const {
  useFetchAboutUsQuery,
  useSubmitAboutUsMutation,
  useFetchAboutUsNewQuery,
  useSubmitAboutUsNewMutation
} = aboutUsApi;

export const privacyPolicyApi = createApi({
  tagTypes: ["privacyPolicy"],
  reducerPath: "privacyPolicyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchPrivacyPolicy: builder.query({
      query: (payload) => ({
        url: "appsetting/privacyPolicy",
        method: "GET",
      }),
      providesTags: ["privacyPolicy"],
    }),
    submitPrivacyPolicy: builder.mutation({
      query: (payload) => ({
        url: "appsetting/privacyPolicy",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["privacyPolicy"],
    }),
  }),
});
export const {
  useFetchPrivacyPolicyQuery,
  useSubmitPrivacyPolicyMutation
} = privacyPolicyApi;


export const copyRightApi = createApi({
  tagTypes: ["copyRight"],
  reducerPath: "copyRightApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchCopyRight: builder.query({
      query: (payload) => ({
        url: "appsetting/copyright",
        method: "GET",
      }),
      providesTags: ["copyRight"],
    }),
    submitCopyRight: builder.mutation({
      query: (payload) => ({
        url: "appsetting/copyright",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["privacyPolicy"],
    }),
  }),
});
export const {
  useFetchCopyRightQuery,
  useSubmitCopyRightMutation
} = copyRightApi;

export const refundPolicyApi = createApi({
  tagTypes: ["refundPolicy"],
  reducerPath: "refundPolicyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchRefundPolicy: builder.query({
      query: (payload) => ({
        url: "appsetting/refundpolicy",
        method: "GET",
      }),
      providesTags: ["refundPolicy"],
    }),
    submitRefundPolicy: builder.mutation({
      query: (payload) => ({
        url: "appsetting/refundpolicy",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["refundPolicy"],
    }),
  }),
});
export const {
  useFetchRefundPolicyQuery,
  useSubmitRefundPolicyMutation
} = refundPolicyApi;

export const termConditionApi = createApi({
  tagTypes: ["termCondition"],
  reducerPath: "termConditionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchTermCondition: builder.query({
      query: (payload) => ({
        url: "appsetting/termconditions",
        method: "GET",
      }),
      providesTags: ["termCondition"],
    }),
    submitTermCondition: builder.mutation({
      query: (payload) => ({
        url: "appsetting/termconditions",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["termCondition"],
    }),
  }),
});
export const {
  useFetchTermConditionQuery,
  useSubmitTermConditionMutation
} = termConditionApi;

export const blogApi = createApi({
  tagTypes: ["blog","blog-category"],
  reducerPath: "blogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    blogList: builder.mutation({
      query: (payload) => ({
        url: "blog/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["blog"],
    }),
    submitBlog: builder.mutation({
      query: (payload) => ({
        url: "blog",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["blog"],
    }),
    blogById: builder.query({
      query: (id) => ({
        url: `blog/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["blog"],
    }),
    blogBySeoUrl: builder.query({
      query: (id) => ({
        url: `blog/view/${id}`,
        method: "GET",
      }),
      providesTags: ["blog"],
    }),
    deleteBlog: builder.mutation({
      query: (id) => ({
        url: `blog/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["blog"],
    }),
    downloadBlogCsv: builder.mutation({
      query: (payload) => ({
        url: "blog/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["blog"],
    }),
    blogCategoryList: builder.mutation({
      query: (payload) => ({
        url: "blog/category/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["blog-category"],
    }),
    submitBlogCategory: builder.mutation({
      query: (payload) => ({
        url: "blog/category",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["blog-category"],
    }),
    blogCategoryById: builder.query({
      query: (id) => ({
        url: `blog/category/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["blog-category"],
    }),
    deleteBlogCategory: builder.mutation({
      query: (id) => ({
        url: `blog/category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["blog-category"],
    }),
    downloadBlogCategoryCsv: builder.mutation({
      query: (payload) => ({
        url: "blog/category/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["blog-category"],
    }),
    getAllBlogCategoryDropdown: builder.query({
      query: () => ({
        url: "blog/category/dropdown/list",
        method: "GET",
      }),
      providesTags: ["blog-category"],
    }),
  }),
});
export const {
  useBlogListMutation,
  useSubmitBlogMutation,
  useBlogByIdQuery,
  useDeleteBlogMutation,
  useDownloadBlogCsvMutation,
  useBlogCategoryListMutation,
  useSubmitBlogCategoryMutation,
  useBlogCategoryByIdQuery,
  useDeleteBlogCategoryMutation,
  useDownloadBlogCategoryCsvMutation,
  useGetAllBlogCategoryDropdownQuery,
  useBlogBySeoUrlQuery
} = blogApi;


export const certificateApi = createApi({
  tagTypes: ["certificate"],
  reducerPath: "certificateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    certificateList: builder.mutation({
      query: (payload) => ({
        url: "certificate/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["certificate"],
    }),
    submitCertificate: builder.mutation({
      query: (payload) => ({
        url: "certificate",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["certificate"],
    }),
    certificateById: builder.query({
      query: (id) => ({
        url: `certificate/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["certificate"],
    }),
    deleteCertificate: builder.mutation({
      query: (id) => ({
        url: `certificate/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["certificate"],
    }),
    downloadCertificateCsv: builder.mutation({
      query: (payload) => ({
        url: "certificate/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["certificate"],
    }),
  }),
});
export const {
  useCertificateListMutation,
  useSubmitCertificateMutation,
  useCertificateByIdQuery,
  useDeleteCertificateMutation,
  useDownloadCertificateCsvMutation,
} = certificateApi;

export const bannerApi = createApi({
  tagTypes: ["banner"],
  reducerPath: "bannerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    bannerList: builder.mutation({
      query: (payload) => ({
        url: "banner/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["banner"],
    }),
    submitBanner: builder.mutation({
      query: (payload) => ({
        url: "banner",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["banner"],
    }),
    bannerById: builder.query({
      query: (id) => ({
        url: `banner/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["banner"],
    }),
    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `banner/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["banner"],
    }),
    downloadBannerCsv: builder.mutation({
      query: (payload) => ({
        url: "banner/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["banner"],
    }),
  }),
});

export const {
  useBannerListMutation,
  useSubmitBannerMutation,
  useBannerByIdQuery,
  useDeleteBannerMutation,
  useDownloadBannerCsvMutation,
} = bannerApi;


export const linksApi = createApi({
  tagTypes: ["links"],
  reducerPath: "linksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchPrivacyLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/privacypolicy-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    fetchRefundLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/refundpolicy-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    fetchTopLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/top-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    fetchQuickLinks: builder.query({
      query: (payload) => ({
        url: "appsetting/quick-links",
        method: "GET",
      }),
      providesTags: ["links"],
    }),
    submitPrivacyLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/privacypolicy-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
    submitRefundLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/refundpolicy-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
    submitTopLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/top-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
    submitQuickLinks: builder.mutation({
      query: (payload) => ({
        url: "appsetting/quick-links",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["links"],
    }),
  }),
});
export const {
  useFetchPrivacyLinksQuery,
  useFetchRefundLinksQuery,
  useFetchTopLinksQuery,
  useFetchQuickLinksQuery,
  useSubmitPrivacyLinksMutation,
  useSubmitRefundLinksMutation,
  useSubmitTopLinksMutation,
  useSubmitQuickLinksMutation
} = linksApi;

export const reqBrochureMailApi = createApi({
  tagTypes: ["reqBrochureMail"],
  reducerPath: "reqBrochureMailApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    reqBrochureList: builder.mutation({
      query: (payload) => ({
        url: "course/reqbroucher/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["reqBrochureMail"],
    }),
    reqMailList: builder.mutation({
      query: (payload) => ({
        url: "reqmail/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["reqBrochureMail"],
    }),
    downloadBrochureCsv: builder.mutation({
      query: (payload) => ({
        url: "course/reqbroucher/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["reqBrochureMail"],
    }),
    downloadMailCsv: builder.mutation({
      query: (payload) => ({
        url: "reqmail/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["reqBrochureMail"],
    }),
  }),
});

export const {
  useReqBrochureListMutation,
  useReqMailListMutation,
  useDownloadBrochureCsvMutation,
  useDownloadMailCsvMutation
} = reqBrochureMailApi;


export const tutorialApi = createApi({
  tagTypes: ["tutorial","tutorial-category"],
  reducerPath: "tutorialApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    tutorialList: builder.mutation({
      query: (payload) => ({
        url: "tutorial/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["tutorial"],
    }),
    submitTutorial: builder.mutation({
      query: (payload) => ({
        url: "tutorial",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tutorial"],
    }),
    tutorialById: builder.query({
      query: (id) => ({
        url: `tutorial/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["tutorial"],
    }),
    tutorialBySeoUrl: builder.query({
      query: (id) => ({
        url: `tutorial/view/${id}`,
        method: "GET",
      }),
      providesTags: ["tutorial"],
    }),
    deleteTutorial: builder.mutation({
      query: (id) => ({
        url: `tutorial/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tutorial"],
    }),
    downloadTutorialCsv: builder.mutation({
      query: (payload) => ({
        url: "tutorial/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tutorial"],
    }),
    tutorialCategoryList: builder.mutation({
      query: (payload) => ({
        url: "tutorial/category/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["tutorial-category"],
    }),
    submitTutorialCategory: builder.mutation({
      query: (payload) => ({
        url: "tutorial/category",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tutorial-category"],
    }),
    tutorialCategoryById: builder.query({
      query: (id) => ({
        url: `tutorial/category/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["tutorial-category"],
    }),
    deleteTutorialCategory: builder.mutation({
      query: (id) => ({
        url: `tutorial/category/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tutorial-category"],
    }),
    downloadTutorialCategoryCsv: builder.mutation({
      query: (payload) => ({
        url: "tutorial/category/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tutorial-category"],
    }),
    getAllTutorialCategoryDropdown: builder.query({
      query: () => ({
        url: "tutorial/category/dropdown/list",
        method: "GET",
      }),
      providesTags: ["tutorial-category"],
    }),
  }),
});
export const {
  useTutorialListMutation,
  useSubmitTutorialMutation,
  useTutorialByIdQuery,
  useDeleteTutorialMutation,
  useDownloadTutorialCsvMutation,
  useTutorialCategoryListMutation,
  useSubmitTutorialCategoryMutation,
  useTutorialCategoryByIdQuery,
  useDeleteTutorialCategoryMutation,
  useDownloadTutorialCategoryCsvMutation,
  useGetAllTutorialCategoryDropdownQuery,
  useTutorialBySeoUrlQuery
} = tutorialApi;



export const landingPageOneApi = createApi({
  tagTypes: ["landingPageOne"],
  reducerPath: "landingPageOneApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    landingPageOneList: builder.mutation({
      query: (payload) => ({
        url: "langingpage/one/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["landingPageOne"],
    }),
    submitLandingPageOne: builder.mutation({
      query: (payload) => ({
        url: "langingpage/one",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["landingPageOne"],
    }),
    landingPageOneBySeoUrl: builder.query({
      query: (id) => ({
        url: `langingpage/one/byseo/${id}`,
        method: "GET",
      }),
      providesTags: ["landingPageOne"],
    }),
    deleteLandingPageOne: builder.mutation({
      query: (id) => ({
        url: `langingpage/one/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["landingPageOne"],
    }),
  }),
});
export const {
  useLandingPageOneListMutation,
  useLandingPageOneBySeoUrlQuery,
  useSubmitLandingPageOneMutation,
  useDeleteLandingPageOneMutation
} = landingPageOneApi;


export const landingPageTwoApi = createApi({
  tagTypes: ["landingPageTwo"],
  reducerPath: "landingPageTwoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    landingPageTwoList: builder.mutation({
      query: (payload) => ({
        url: "langingpage/two/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["landingPageTwo"],
    }),
    landingPageTwoBySeoUrl: builder.query({
      query: (id) => ({
        url: `langingpage/two/byseo/${id}`,
        method: "GET",
      }),
      providesTags: ["landingPageTwo"],
    }),
    submitLandingPageTwo: builder.mutation({
      query: (payload) => ({
        url: "langingpage/two",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["landingPageTwo"],
    }),
    deleteLandingPageTwo: builder.mutation({
      query: (id) => ({
        url: `langingpage/two/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["landingPageTwo"],
    }),
  }),
});
export const {
  useLandingPageTwoListMutation,
  useLandingPageTwoBySeoUrlQuery,
  useSubmitLandingPageTwoMutation,
  useDeleteLandingPageTwoMutation
} = landingPageTwoApi;


export const salesApi = createApi({
  tagTypes: ["sales"],
  reducerPath: "salesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    salesList: builder.mutation({
      query: (payload) => ({
        url: "placed/widget/list",
        method: "POST",
        body: payload,
      }),
      providesTags: ["sales"],
    }),
    submitSales: builder.mutation({
      query: (payload) => ({
        url: "placed/widget",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["sales"],
    }),
    salesById: builder.query({
      query: (id) => ({
        url: `placed/widget/byid/${id}`,
        method: "GET",
      }),
      providesTags: ["sales"],
    }),
    deleteSales: builder.mutation({
      query: (id) => ({
        url: `placed/widget/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sales"],
    }),
    downloadSalesCsv: builder.mutation({
      query: (payload) => ({
        url: "placed/widget/csv",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["sales"],
    }),
  }),
});

export const {
  useSalesListMutation,
  useSubmitSalesMutation,
  useSalesByIdQuery,
  useDeleteSalesMutation,
  useDownloadSalesCsvMutation,
} = salesApi;


export const tutorialBannerApi = createApi({
  tagTypes: ["tutorial-banner"],
  reducerPath: "tutorialBannerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchTutorialBanner: builder.query({
      query: (payload) => ({
        url: "appsetting/tutorial/banner",
        method: "GET",
      }),
      providesTags: ["tutorial-banner"],
    }),
    submitTutorialBanner: builder.mutation({
      query: (payload) => ({
        url: "appsetting/tutorial/banner",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tutorial-banner"],
    }),
  }),
});
export const {
  useFetchTutorialBannerQuery,
  useSubmitTutorialBannerMutation
} = tutorialBannerApi;


export const notificationApi = createApi({
  tagTypes: ["notification"],
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Authorization', user?.token);
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchNotification: builder.query({
      query: (payload) => ({
        url: "appsetting/notification",
        method: "GET",
      }),
      providesTags: ["notification"],
    }),
    submitNotification: builder.mutation({
      query: (payload) => ({
        url: "appsetting/notification",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["notification"],
    }),
  }),
});
export const {
  useFetchNotificationQuery,
  useSubmitNotificationMutation
} = notificationApi;