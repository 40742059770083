import {
  Award,
  Bell,
  BookOpen,
  Briefcase,
  Clock,
  Eye,
  File,
  Image,
  Info,
  Layers,
  MapPin,
  Send,
  ShoppingBag,
  User,
} from "react-feather";


export const superAdminSideMenu = [
  {
    label: "Admins",
    to: "/admins-list",
    icon: <User />,
  },
  {
    label: "Leads",
    to: "/enquiry-list",
    icon: <Info />,
  },
]

export const AdminSideMenu = [
  {
    label: "Users",
    to: "/users-list",
    icon: <User />,
  },
]

export const sideMenu = [
  {
    label: "Mail Send",
    isActive: false,
    icon: <Send />,
    children: [
      { label: "Single Mail Send", to: "/single-mail-send", icon: <Send /> },
      { label: "Bulk Mail Send", to: "/bulk-mail-send", icon: <Send />},
    ],
  },
  // {
  //   label: "Mail Send",
  //   isActive: false,
  //   to: "/mail-send",
  //   icon: <Send />,
  // },
  {
    label: "Sender",
    to: "/sender-list",
    icon: <Info />,
  },
  {
    label: "Status",
    to: "/status-list",
    icon: <Info />,
  },

  {
    label: "Template",
    to: "/template-list",
    icon: <Image />,
  },

  // {
  //   label: "Testimonials",
  //   to: "/testimonial-list",
  //   icon: <User />,
  // },
  // {
  //   label: "Banner",
  //   to: "/banner-list",
  //   icon: <Info />,
  // },
  // {
  //   label: "Sales",
  //   to: "/sales-list",
  //   icon: <Info />,
  // },
  // {
  //   label: "Landing Page 1",
  //   to: "/landing-page-1",
  //   icon: <Info />,
  // },
  // {
  //   label: "Landing Page 2",
  //   to: "/landing-page-2",
  //   icon: <Info />,
  // },
  
 
  // // {
  // //   label: "FAQ",
  // //   isActive: false,
  // //   icon: <File />,
  // //   children: [
  // //     { label: "FAQ Category", to: "/faq-category-list", icon: <File /> },
  // //     { label: "FAQ List", to: "/faq-list", icon: <File /> },
  // //   ],
  // // },
  // {
  //   label: "Blog",
  //   isActive: false,
  //   icon: <File />,
  //   children: [
  //     { label: "Blog Category", to: "/blog-category-list", icon: <File /> },
  //     { label: "Blog List", to: "/blog-list", icon: <File /> },
  //   ],
  // },
  // {
  //   label: "Tutorial",
  //   isActive: false,
  //   icon: <File />,
  //   children: [
  //     { label: "Tutorial Category", to: "/tutorial-category-list", icon: <File /> },
  //     { label: "Tutorial List", to: "/tutorial-list", icon: <File /> },
  //   ],
  // },
  // {
  //   label: "Tutorial Banner",
  //   to: "/tutorial-banner",
  //   icon: <Info />,
  // },
  // {
  //   label: "Placement",
  //   isActive: false,
  //   icon: <Briefcase />,
  //   children: [
  //     { label: "Drive", to: "/drive-list", icon: <Briefcase /> },
  //     { label: "Placed Student", to: "/placed-student-list", icon: <Briefcase /> },
  //     // { label: "Student Registration", to: "/student-reg-list", icon: <Briefcase /> },
  //   ],
  // },
  // {
  //   label: "Certificate Request",
  //   to: "/certificate-req-list",
  //   icon: <Award />,
  // },
  // {
  //   label: "Brochure Request",
  //   to: "/req-brochure-list",
  //   icon: <Info />,
  // },
  // {
  //   label: "Join Mail Request",
  //   to: "/req-join-mail-list",
  //   icon: <Info />,
  // },
  // {
  //   label: "Refund Policy",
  //   to: "/refund-policy",
  //   icon: <Info />,
  // },
  // {
  //   label: "Term and Condition",
  //   to: "/term-condition",
  //   icon: <Info />,
  // },
  // {
  //   label: "Privacy Policy",
  //   to: "/privacy-policy",
  //   icon: <Info />,
  // },
  // {
  //   label: "copy-right",
  //   to: "/copy-right",
  //   icon: <Info />,
  // },
  // {
  //   label: "Contact Us",
  //   to: "/contactUs-list",
  //   icon: <User />,
  // },
  
  // {
  //   label: "About Us",
  //   to: "/aboutUs-form",
  //   icon: <User />,
  // },
  // {
  //   label: "Quick Links",
  //   to: "/quick-links",
  //   icon: <User />,
  // },
  // {
  //   label: "Top Courses Links",
  //   to: "/top-course-links",
  //   icon: <User />,
  // },
  // {
  //   label: "Privacy Policy Links",
  //   to: "/privacy-policy-links",
  //   icon: <User />,
  // },
  // {
  //   label: "Refund Policy Links",
  //   to: "/refund-policy-links",
  //   icon: <User />,
  // },
];
