import { createSlice } from "@reduxjs/toolkit";

const initState = {
  salesList: [],
  salesView: null,
  salesEdit: null,
};

export const salesSlice = createSlice({
  name: "sales",
  initialState: initState,
  reducers: {
    getSales: (state, { payload }) => {
      state.salesList = payload;
    },
    setSalesView: (state, { payload }) => {
      state.salesView = payload;
    },
    setSalesEdit: (state, { payload }) => {
      state.salesEdit = payload;
    },
  },
});

export const { getSales, setSalesView, setSalesEdit } =
  salesSlice.actions;
export default salesSlice.reducer;
