import { createSlice } from "@reduxjs/toolkit";

const initState = {
  driveList: [],
  driveView: null,
  driveEdit: null,
  placedStudentList: [],
  placedStudentView: null,
  placedStudentEdit: null,
  studentRegList: [],
  studentRegView: null,
  studentRegEdit: null,
};

export const placementSlice = createSlice({
  name: "placement",
  initialState: initState,
  reducers: {
    getDrive: (state, { payload }) => {
      state.driveList = payload;
    },
    setDriveView: (state, { payload }) => {
      state.driveView = payload;
    },
    setDriveEdit: (state, { payload }) => {
      state.driveEdit = payload;
    },
    getPlacedStudent: (state, { payload }) => {
      state.placedStudentList = payload;
    },
    setPlacedStudentView: (state, { payload }) => {
      state.placedStudentView = payload;
    },
    setPlacedStudentEdit: (state, { payload }) => {
      state.placedStudentEdit = payload;
    },
    getStudentReg: (state, { payload }) => {
      state.studentRegList = payload;
    },
    setStudentRegView: (state, { payload }) => {
      state.studentRegView = payload;
    },
    setStudentRegEdit: (state, { payload }) => {
      state.studentRegEdit = payload;
    },
  },
});

export const { 
  getDrive, 
  setDriveView, 
  setDriveEdit,
  getPlacedStudent,
  setPlacedStudentView,
  setPlacedStudentEdit,
  getStudentReg,
  setStudentRegView,
  setStudentRegEdit
 } =
placementSlice.actions;
export default placementSlice.reducer;
