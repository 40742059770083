import { createSlice } from "@reduxjs/toolkit";

const initState = {
  clientList: [],
  clientView: null,
  clientEdit: null,
};

export const clientSlice = createSlice({
  name: "client",
  initialState: initState,
  reducers: {
    getClient: (state, { payload }) => {
      state.clientList = payload;
    },
    setClientView: (state, { payload }) => {
      state.clientView = payload;
    },
    setClientEdit: (state, { payload }) => {
      state.clientEdit = payload;
    },
  },
});

export const { getClient, setClientView, setClientEdit } =
  clientSlice.actions;
export default clientSlice.reducer;
