import { createSlice } from "@reduxjs/toolkit";

const initState = {
  faqList: [],
  faqView: null,
  faqEdit: null,
  faqCategoryList: [],
  faqCategoryView: null,
  faqCategoryEdit: null,
};

export const faqSlice = createSlice({
  name: "faq",
  initialState: initState,
  reducers: {
    getFaq: (state, { payload }) => {
      state.faqList = payload;
    },
    setFaqView: (state, { payload }) => {
      state.faqView = payload;
    },
    setFaqEdit: (state, { payload }) => {
      state.faqEdit = payload;
    },
    getFaqCategory: (state, { payload }) => {
        state.faqCategoryList = payload;
      },
      setFaqCategoryView: (state, { payload }) => {
        state.faqCategoryView = payload;
      },
      setFaqCategoryEdit: (state, { payload }) => {
        state.faqCategoryEdit = payload;
      },
  },
});

export const { getFaq, setFaqView, setFaqEdit,
getFaqCategory,setFaqCategoryView,setFaqCategoryEdit
} =
  faqSlice.actions;
export default faqSlice.reducer;
