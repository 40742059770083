import { createSlice } from "@reduxjs/toolkit";

const initState = {
  contactUsList: [],
  contactUsView: null,
  contactUsEdit: null,
};

export const contactUsSlice = createSlice({
  name: "contact",
  initialState: initState,
  reducers: {
    getContactUs: (state, { payload }) => {
      state.contactUsList = payload;
    },
    setContactUsView: (state, { payload }) => {
      state.contactUsView = payload;
    },
    setContactUsEdit: (state, { payload }) => {
      state.contactUsEdit = payload;
    },
  },
});

export const { getContactUs, setContactUsView, setContactUsEdit } =
  contactUsSlice.actions;
export default contactUsSlice.reducer;
