import { lazy } from "react";
import BlogList from "../components/blog/BlogList";
import BlogForm from "../components/blog/BlogForm";
import BlogView from "../components/blog/BlogView";
import BlogCategoryList from "../components/blog-category/BlogCategoryList";
import BlogCategoryForm from "../components/blog-category/BlogCategoryForm";
import BlogCategoryView from "../components/blog-category/BlogCategoryView";
import CertificateList from "../components/certificate/CertificateList";
import CertificateForm from "../components/certificate/CertificateForm";
import CertificateView from "../components/certificate/CertificateView";
import LoginComponent from "../components/auth/Login";
import ReqBrochure from "../components/req-brochure/reqBrochure";
import ReqJoinMail from "../components/req-join-mail/reqJoinMail";
import QuickLinks from "../components/footerLinks/quickLinks";
import TopCourseLinks from "../components/footerLinks/topCourseLinks";
import PrivacyPolicyLinks from "../components/footerLinks/privacyPolicyLinks";
import RefundPolicyLinks from "../components/footerLinks/refundPolicyLinks";
import LandingPageListTwo from "../components/landing-page/LandingPageListTwo";
import LandingPageListOne from "../components/landing-page/LandingPageListOne";
import SPView from "../components/students/SPView";


const CourseList = lazy(() => import("../components/courses/CourseList"));
const CourseForm = lazy(() => import("../components/courses/CourseForm"));
const CourseView = lazy(() => import("../components/courses/CourseView"));

const CategoryList = lazy(() =>
  import("../components/course-category/CategoryList")
);
const CategoryForm = lazy(() =>
  import("../components/course-category/CategoryForm")
);
const CategoryView = lazy(() =>
  import("../components/course-category/CategoryView")
);

const MailSend = lazy(() => import("../components/mail-send/MailSend"));
const SingleMailSend = lazy(() => import("../components/mail-send/SingleMailSend"));
const BatchView = lazy(() => import("../components/batch/BatchView"));
const CenterList = lazy(() => import("../components/centers/CenterList"));
const CenterForm = lazy(() => import("../components/centers/CenterForm"));
const CenterView = lazy(() => import("../components/centers/CenterView"));

const StatusList = lazy(() => import("../components/mail-status/StatusList"));
const StatusListV2 = lazy(() => import("../components/mail-status/StatusListV2"));
const EnquiryList = lazy(() => import("../components/enquiry/EnquiryList"));
const EnquiryForm = lazy(() => import("../components/enquiry/EnquiryForm"));
const EnquiryView = lazy(() => import("../components/enquiry/EnquiryView"));

const BannerList = lazy(() => import("../components/banner/BannerList"));
const BannerForm = lazy(() => import("../components/banner/BannerForm"));
const BannerView = lazy(() => import("../components/banner/BannerView"));

const ClientView = lazy(() => import("../components/clients/ClientView"));
const SenderForm = lazy(() => import("../components/sender/AddSender"));
const SenderList = lazy(() => import("../components/sender/SenderList"));

const GallaryView = lazy(() => import("../components/gallary/GallaryView"));
const UserForm = lazy(() => import("../components/user-mangement/UserForm"));
const UserList = lazy(() => import("../components/user-mangement/UserList"));

const AdminForm = lazy(() => import("../components/admin-mangement/AdminForm"));
const adminList = lazy(() => import("../components/admin-mangement/AdminList"));

const TestimonialView = lazy(() => import("../components/testimonials/TestimonialView"));
const TemplateForm = lazy(() => import("../components/template_management/TemplateForm"));
const TemplateList = lazy(() => import("../components/template_management/TemplateList"));

const DriveView = lazy(() => import("../components/placement/drive/DriveView"));
const DriveForm = lazy(() => import("../components/placement/drive/DriveForm"));
const DriveList = lazy(() => import("../components/placement/drive/DriveList"));

const PlaceStudentView = lazy(() => import("../components/placement/placed-student/PlaceStudentView"));
const PlaceStudentForm = lazy(() => import("../components/placement/placed-student/PlaceStudentForm"));
const PlaceStudentList = lazy(() => import("../components/placement/placed-student/PlaceStudentList"));

const StudentRegView = lazy(() => import("../components/placement/student-reg/StudentRegView"));
const StudentRegForm = lazy(() => import("../components/placement/student-reg/StudentRegForm"));
const StudentRegList = lazy(() => import("../components/placement/student-reg/StudentRegList"));

const ContactUsView = lazy(() => import("../components/contact-us/ContactUsView"));
const ContactUsForm = lazy(() => import("../components/contact-us/ContactUsForm"));
const ContactUsList = lazy(() => import("../components/contact-us/ContactUsList"));

const AboutUsForm = lazy(() => import("../components/about-us/AboutUsForm"));
const PrivacyPolicyForm = lazy(() => import("../components/privacy-policy/PrivacyPolicyForm"));
const CopyRightForm = lazy(() => import("../components/copy-right/CopyRightForm"));
const RefundPolicyForm = lazy(() => import("../components/refund-policy/RefundPolicyForm"));
const TermConditionForm = lazy(() => import("../components/term-condition/TermConditionForm"));

const FaqView = lazy(() => import("../components/faq/FaqView"));
const FaqForm = lazy(() => import("../components/faq/FaqForm"));
const FaqList = lazy(() => import("../components/faq/FaqList"));

const FaqCategoryView = lazy(() => import("../components/faq-category/FaqCategoryView"));
const FaqCategoryForm = lazy(() => import("../components/faq-category/FaqCategoryForm"));
const FaqCategoryList = lazy(() => import("../components/faq-category/FaqCategoryList"));

const TutorialList = lazy(() => import("../components/tutorial/TutorialList"));
const TutorialForm = lazy(() => import("../components/tutorial/TutorialForm"));
const TutorialView = lazy(() => import("../components/tutorial/TutorialView"));
const TutorialCategoryList = lazy(() => import("../components/tutorial-category/TutorialCategoryList"));
const TutorialCategoryForm = lazy(() => import("../components/tutorial-category/TutorialCategoryForm"));
const TutorialCategoryView = lazy(() => import("../components/tutorial-category/TutorialCategoryView"));

const LandingPageFormOne = lazy(() => import("../components/landing-page/LandingPageFormOne"));
const LandingPageFormTwo = lazy(() => import("../components/landing-page/LandingPageFormTwo"));

const TBForm = lazy(() => import("../components/turorial-banner/TBForm"));
const SPForm = lazy(() => import("../components/students/SPForm"));
const SPList = lazy(() => import("../components/students/SPList"));



export const privateRoutes = [
  // { path: "/course-list", Component: CourseList },
  // { path: "/course-form", Component: CourseForm },
  // { path: "/course-view/:id", Component: CourseView },
  // { path: "/category-list", Component: CategoryList },
  // { path: "/category-form", Component: CategoryForm },
  // { path: "/category-view/:id", Component: CategoryView },
  { path: "/single-mail-send", Component: SingleMailSend },
  { path: "/bulk-mail-send", Component: MailSend },

  // { path: "/batch-view/:id", Component: BatchView },
  // { path: "/center-list", Component: CenterList },
  // { path: "/center-form", Component: CenterForm },
  // { path: "/center-view/:id", Component: CenterView },
  { path: "/status-list", Component: StatusList },
  { path: "/status-list-v2", Component: StatusListV2 },




  { path: "/sender-list", Component: SenderList },
  { path: "/sender-form", Component: SenderForm },
  // { path: "/client-view/:id", Component: ClientView },


  // { path: "/gallary-view/:id", Component: GallaryView },

  { path: "/template-list", Component: TemplateList },
  { path: "/template-form", Component: TemplateForm },
  // { path: "/testimonial-view/:id", Component: TestimonialView },

  // { path: "/drive-list", Component: DriveList },
  // { path: "/drive-form", Component: DriveForm },
  // { path: "/drive-view/:id", Component: DriveView },

  // { path: "/placed-student-list", Component: PlaceStudentList },
  // { path: "/placed-student-form", Component: PlaceStudentForm },
  // { path: "/placed-student-view/:id", Component: PlaceStudentView },

  // { path: "/student-reg-list", Component: StudentRegList },
  // { path: "/student-reg-form", Component: StudentRegForm },
  // { path: "/student-reg-view/:id", Component: StudentRegView },

  // { path: "/contactUs-list", Component: ContactUsList },
  // { path: "/contactUs-form", Component: ContactUsForm },
  // { path: "/contactUs-view/:id", Component: ContactUsView },

  // { path: "/faq-list", Component: FaqList },
  // { path: "/faq-form", Component: FaqForm },
  // { path: "/faq-view/:id", Component: FaqView },

  // { path: "/faq-category-list", Component: FaqCategoryList },
  // { path: "/faq-category-form", Component: FaqCategoryForm },
  // { path: "/faq-category-view/:id", Component: FaqCategoryView },

  // { path: "/blog-list", Component: BlogList },
  // { path: "/blog-form", Component: BlogForm },
  // { path: "/blog-view/:id", Component: BlogView },

  // { path: "/blog-category-list", Component: BlogCategoryList },
  // { path: "/blog-category-form", Component: BlogCategoryForm },
  // { path: "/blog-category-view/:id", Component: BlogCategoryView },

  // { path: "/certificate-req-list", Component: CertificateList },
  // { path: "/certificate-req-form", Component: CertificateForm },
  // { path: "/certificate-req-view/:id", Component: CertificateView },

  // { path: "/aboutUs-form", Component: AboutUsForm },
  // { path: "/privacy-policy", Component: PrivacyPolicyForm },
  // { path: "/copy-right", Component: CopyRightForm },
  // { path: "/refund-policy", Component: RefundPolicyForm },
  // { path: "/term-condition", Component: TermConditionForm },

  // { path: "/banner-list", Component: BannerList },
  // { path: "/banner-form", Component: BannerForm },
  // { path: "/banner-view/:id", Component: BannerView },

  // { path: "/req-brochure-list", Component: ReqBrochure },
  // { path: "/req-join-mail-list", Component: ReqJoinMail },

  // { path: "/quick-links", Component: QuickLinks },
  // { path: "/top-course-links", Component: TopCourseLinks },
  // { path: "/privacy-policy-links", Component: PrivacyPolicyLinks },
  // { path: "/refund-policy-links", Component: RefundPolicyLinks },


  // { path: "/tutorial-list", Component: TutorialList },
  // { path: "/tutorial-form", Component: TutorialForm },
  // { path: "/tutorial-view/:id", Component: TutorialView },

  // { path: "/tutorial-category-list", Component: TutorialCategoryList },
  // { path: "/tutorial-category-form", Component: TutorialCategoryForm },
  // { path: "/tutorial-category-view/:id", Component: TutorialCategoryView },

  // { path: "/landing-page-one-form", Component: LandingPageFormOne },
  // { path: "/landing-page-two-form", Component: LandingPageFormTwo },
  // { path: "/landing-page-1", Component: LandingPageListOne },
  // { path: "/landing-page-2", Component: LandingPageListTwo },

  // { path: "/sales-form", Component: SPForm },
  // { path: "/sales-list", Component: SPList },
  // { path: "/sales-view/:id", Component: SPView },

  // { path: "/tutorial-banner", Component: TBForm },


];

export const superAdminRoutes =[
  { path: "/admins-list", Component: adminList },
  { path: "/admin-form", Component: AdminForm },

  { path: "/enquiry-list", Component: EnquiryList },
  { path: "/enquiry-form", Component: EnquiryForm },
  { path: "/enquiry-view/:id", Component: EnquiryView },
]

export const AdminRoutes =[
  { path: "/users-list", Component: UserList },
  { path: "/user-form", Component: UserForm },
]

export const publicRoutes = [
  { path: "/login", Component: LoginComponent },
]
