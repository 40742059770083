import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import { useBlogCategoryByIdQuery } from "../../service";
import { setClientView } from "../../redux/clientSlice";
import { setBlogCategoryView } from "../../redux/blogSlice";

function BlogCategoryView() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewData = useSelector((state) => state.blogState.blogCategoryView);
  const resBlogCategoryById = useBlogCategoryByIdQuery(params.id);

  useEffect(() => {
    if (resBlogCategoryById?.isSuccess) {
      dispatch(setBlogCategoryView(resBlogCategoryById?.data?.data));
    }
  }, [resBlogCategoryById]);

  return (
    <Card>
      <CardHeader className="align-items-end">
        <h5 className="text-center">Blog Category Details: {viewData?.title}</h5>
        <Button color="primary" onClick={() => navigate("/blog-category-list")}>
          Back
        </Button>
      </CardHeader>
      <CardBody>
        <Table bordered>
          <tbody>
            <tr>
              <th style={{ width: "20%" }}>Title</th>
              <td style={{ width: "80%" }}>{viewData?.title}</td>
            </tr>
            <tr>
              <th style={{ width: "20%" }}>Image</th>
              <td style={{ width: "80%" }}>
                {viewData?.image?.filepath ? (
                  <img src={viewData?.image?.filepath} height={50} width={50} />
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <th style={{ width: "20%" }}>Remark</th>
              <td style={{ width: "80%" }}>{viewData?.remark}</td>
            </tr>
            <tr>
              <th style={{ width: "20%" }}>Sort Order</th>
              <td style={{ width: "80%" }}>{viewData?.order_no}</td>
            </tr>
            <tr>
              <th style={{ width: "20%" }}>Status</th>
              <td style={{ width: "80%" }}>
                <Badge
                  color={`text-capitalize badge ${
                    viewData?.status?.value === "Active"
                      ? "bg-light-success"
                      : "bg-light-danger"
                  } rounded-pill`}
                  pill
                >
                  {viewData?.status?.value}
                </Badge>
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

export default BlogCategoryView;
