import { createSlice } from "@reduxjs/toolkit";

const initState = {
  adminList: [],
  adminView: null,
  adminEdit: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: initState,
  reducers: {
    getadmin: (state, { payload }) => {
      state.adminList = payload;
    },
    setadminView: (state, { payload }) => {
      state.adminView = payload;
    },
    setadminEdit: (state, { payload }) => {
      state.adminEdit = payload;
    },
  },
});

export const {
  getadmin,
  setadminView,
  setadminEdit,
  setadminDropDown,
} = adminSlice.actions;
export default adminSlice.reducer;
