import { createSlice } from "@reduxjs/toolkit";

const initState = {
  gallaryList: [],
  gallaryView: null,
  gallaryEdit: null,
};

export const gallarySlice = createSlice({
  name: "gallary",
  initialState: initState,
  reducers: {
    getGallary: (state, { payload }) => {
      state.gallaryList = payload;
    },
    setGallaryView: (state, { payload }) => {
      state.gallaryView = payload;
    },
    setGallaryEdit: (state, { payload }) => {
      state.gallaryEdit = payload;
    },
  },
});

export const { getGallary, setGallaryView, setGallaryEdit } =
  gallarySlice.actions;
export default gallarySlice.reducer;
