import { createSlice } from "@reduxjs/toolkit";

const initState = {
  userList: [],
  userView: null,
  userEdit: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    getuser: (state, { payload }) => {
      state.userList = payload;
    },
    setuserView: (state, { payload }) => {
      state.userView = payload;
    },
    setuserEdit: (state, { payload }) => {
      state.userEdit = payload;
    },
  },
});

export const {
  getuser,
  setuserView,
  setuserEdit,
  setuserDropDown,
} = userSlice.actions;
export default userSlice.reducer;
